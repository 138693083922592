* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Circular";
  src: url("./assets/fonts/CircularStd-Book.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Circular";
  src: url("./assets/fonts/CircularStd-Medium.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Circular";
  src: url("./assets/fonts/CircularStd-Bold.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Circular";
  src: url("./assets/fonts/CircularStd-Black.otf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Charter";
  src: url("./assets/fonts/Charter-Regular.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Domaine Display";
  src: url("./assets/fonts/DomaineText-LightItalic.otf") format("truetype");
  font-weight: 500;
}

body {
  font-family: "Circular";
}

.App {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: flex-start;
  height: 100vh;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

.col {
  flex-direction: column;
  height: 100%;
}

.jc-sb {
  justify-content: space-between;
}

.col {
  flex-direction: column;
}

.al-fs {
  align-items: flex-start;
}

.jc-ce {
  justify-content: center;
}

.header {
  font-size: 0.875rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  padding-left: 5.5625rem;
  min-height: 66px;
  padding-right: 2.125rem;
}

.light {
  color: #ababab;
}

.break {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.language {
  margin-left: 1.625rem;
  margin-right: 1.3125rem;
}

.content {
  padding-top: 123px;
  min-height: 0;
}

.content .link,
.content .language {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 1rem 0;
  margin-left: 3.3125rem;
}

.pointer {
  cursor: pointer;
}

.btn {
  height: 2.5rem;
  background: #000000;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  display: flex;
  font-weight: 900;
  align-items: center;
  color: #fff;
  width: max-content;
  cursor: pointer;
  justify-content: center;
  padding: 0.5rem 1rem;
  padding-right: 0.6rem;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  border: 0.0625rem solid #000;
}

.border {
  border-bottom: 1px solid #e2e2e2;
}

.btnLarge {
  min-width: 13.3125rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
}

.modal-subTitle {
  font-weight: 900;
  font-size: 26px;
  line-height: 33px;
}

.modal-subTitle span:first-child {
  font-weight: 900;
  color: #5b58ef;
}

.modal-subTitle span:last-child {
  color: #000;
  font-family: "Charter";
}

.green .modal-subTitle span:first-child {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #32c850;
}

.btn:hover {
  background-color: #fff;
  color: #000;
}

.btn:hover svg path {
  stroke: #000;
}

.btn svg path {
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.btn svg {
  margin-left: 0.5rem;
}

.left {
  padding-left: 5.5625rem;
}

.nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 13px;
  color: #aeb3be;
}

.nav-link a.active {
  color: #000;
}

a {
  text-decoration: none;
  color: inherit;
}

.content {
  display: flex;
  align-items: flex-start;
}

.left {
  padding-top: 1rem;
  white-space: nowrap;
}

.right {
  width: 100%;
  padding-left: 76px;
  height: 100%;
  display: flex;
  min-height: 0;
  padding-bottom: 2rem;
}

.fees {
  width: 100%;
  max-width: 85%;
}

.headerText {
  font-weight: 900;
  font-size: 35px;
  line-height: 44px;
  color: #000000;
  display: flex;
  align-items: center;
}

.smText {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #50555c;
  max-width: 76ch;
}

.headerText .faq {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 11px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

#question {
  resize: none;
  font-family: inherit;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #161616;
  max-width: 800px;
  width: 100%;
  height: 200px;
}

#question::placeholder {
  color: #ababab;
  padding-left: 3px;
  text-transform: none;
}

.barContainer {
  max-width: 377px;
}

.calculate {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #aeb3be;
  max-width: 24ch;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

.modal .bg {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 100vh;
}

.modalContent {
  max-width: 585px;
  width: 100%;
  margin-left: auto;
  position: relative;
  z-index: 11;
  background: #ffffff;
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.15);
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  padding-top: 63px;
  padding-right: 25px;
  padding-left: 53px;
  padding-bottom: 50px;
}

.imgContainer {
  display: flex;
  justify-content: space-between;
  background: #f3f3f3;
  padding-top: 27px;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  height: 250px;
  align-items: flex-end;
  padding-right: 48px;
}

.phnInHand {
  width: 150px;
  margin-left: auto;
}

.yt-link {
  width: 35px;
  height: 35px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 18px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.yt-link:hover {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.close {
  position: absolute;
  top: 10px;
  right: 13px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.modal-title {
  font-weight: 900;
  font-size: 26px;
  line-height: 33px;
  color: #000000;
}

.modalWrapper {
  max-width: 450px;
}

.modal-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #50555c;
  max-width: 98%;
  width: 100%;
}

.learn {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #878787;
  cursor: pointer;
}

.modal-subtitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}

.modal-textArr {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #878787;
  max-width: 44ch;
}

.loadingText {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #50555c;
}

.compareGrid {
  width: 100%;
}

.compareGrid > div {
  background-color: #fafafa;
}

.twoGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 450px));
}

.flinnCompareHeader {
  min-height: 100%;
  align-items: flex-end;
}

.otherLawyersHeader {
  height: 100%;
}

.headerItem {
  height: 113px;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 3.1875rem;
  color: #000000;
  width: 100%;
  padding-left: 3.1875rem;
  padding-right: 2rem;
}

.law-person2 {
  height: 84px;
}

.item {
  padding-top: 19px;
  padding-bottom: 23px;
  padding-right: 47px;
  padding-left: 45px;
  border-top: 2px solid #e2e2e2;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.flinnText {
  font-weight: 900;
  font-size: 28px;
  line-height: 35px;
  align-self: center;
}

.otherLawyersHeader {
  font-weight: 900;
  font-size: 28px;
  line-height: 35px;
}

.loading-content {
  margin-top: -123px;
  width: 100%;
}

.analysisText,
.blurredText {
  font-family: "Charter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.analysisTextContainer {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
  padding-bottom: 3rem;
}

.analysisTextContainer::-webkit-scrollbar {
  display: none;
}

.analysisContainer {
  position: relative;
  display: flex;
  margin-bottom: 70px;
  width: 100%;
  flex-direction: column;
}

.blur {
  position: absolute;
  pointer-events: none;
  content: "";
  width: 100%;
  height: 63px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.btns {
  height: 74px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.nav-link .active {
  color: #000;
}

.money-back {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #50555c;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 0;
  width: 100%;
  height: 100%;
}

@keyframes ldio-dm0swqp3eze {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-dm0swqp3eze div {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 14px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-dm0swqp3eze div {
  animation: ldio-dm0swqp3eze 1s linear infinite;
  top: 50px;
  left: 50px;
  -webkit-animation: ldio-dm0swqp3eze 1s linear infinite;
}
.loadingio-spinner-rolling-3keew3bkh0l {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
  transform: scale(0.2);
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
}
.ldio-dm0swqp3eze {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  -webkit-transform: translateZ(0) scale(1);
  -moz-transform: translateZ(0) scale(1);
  -ms-transform: translateZ(0) scale(1);
  -o-transform: translateZ(0) scale(1);
}

.Toastify__toast--error {
  background-color: #ff4f4f !important;
  color: #fff !important;
  font-family: "Circular" !important;
}

.Toastify__toast--success {
  background-color: rgb(50, 200, 80) !important;
  color: #fff !important;
  font-family: "Circular" !important;
}

.Toastify__toast-icon svg,
.Toastify__close-button svg {
  fill: #fff !important;
}

.Toastify__toast {
  min-height: 54px !important;
}

.analysisText,
.blurredText {
  white-space: break-spaces;
}

div,
span,
p {
  user-select: none;
}

.noHover:hover {
  color: #fff;
  background-color: inherit;
}

.flinnCompare,
.otherLawyersCompare {
  max-width: 450px;
  width: 100%;
  position: relative;
}

.flinnCompare .blur {
  height: 128px;
  background: linear-gradient(
    176.16deg,
    rgba(255, 255, 255, 0) 3.15%,
    #ffffff 62.2%
  );
  bottom: -20px;
}

.otherLawyersCompare .blur {
  height: 73px;
}

.greyed {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.greyed:hover {
  background-color: #000000;
  color: #fff;
}

.greyed:hover svg path {
  stroke: #fff;
}

.mob-only {
  display: none;
}

.mob-only.analysisTextContainer {
  padding-bottom: 0;
}

.pc-only {
  display: inherit;
}

.progress {
  height: 4px;
  background-color: #32c850;
}

.textAreaContainer {
  position: relative;
}

.languageDropdown {
  position: absolute;
  top: 1.5rem;
  z-index: 1;
  left: 0;
  width: 90%;
  margin-top: 1rem;
  margin-right: 1rem;
  z-index: 100;
  text-transform: capitalize;
  background-color: #f3f3f3;
  max-height: 400px;
  overflow: auto;
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.pb-2 {
  padding-bottom: 2rem;
}

.languageDropdown.open {
  opacity: 1;
  visibility: visible;
  animation: swipeUp 0.25s ease;
  -webkit-animation: swipeUp 0.25s ease;
}

@keyframes swipeUp {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes swipeUp {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.languageDropdown::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.languageDropdown::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.lang {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #000;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
}

.lang.active {
  background-color: #000;
  color: #fff;
}

.lang:hover {
  background-color: #fff;
  color: #000;
}

.back {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #aeb3be;
  cursor: pointer;
  max-width: 26ch;
}

.gradientText {
  background: linear-gradient(92.51deg, #32c850 40.27%, #8cff7d 68.63%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-size: 500% auto;
  animation: textShine 3.5s ease-in-out infinite alternate;
  -webkit-animation: textShine 3.5s ease-in-out infinite alternate;
}

.curly {
  font-family: "Domaine Display", serif;
  color: #32c850;
  margin-right: 12px;
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}

.whiteSection {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: min(296px, 30vh);
  left: 0px;
  z-index: 3;
  pointer-events: none;
  width: 100%;
}

.blue-blur {
  background: rgba(140, 255, 125, 0.3);
  filter: blur(450px);
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50%;
  left: 0px;
  transform: rotate(-50deg);
  pointer-events: none;
  -webkit-filter: blur(450px);
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.textSection {
  height: 200px;
}

.legalSection {
  width: 100%;
}

.inputContainer {
  background: #f8f8f8;
  border-radius: 5px;
  max-width: 485px;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #000;
  padding: 16px 22px;
}

.inputContainer input {
  font-weight: 500;
  font-family: inherit;
  width: 100%;
  font-size: 18px;
  line-height: 23px;
  border: none;
  outline: none;
  background-color: transparent;
}

.analysisPage .analysisTextContainer {
  overflow: visible;
}

.comparision-item {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  transform: translateY(20px) translateX(-25%);
  -webkit-transform: translateY(20px) translateX(-25%);
  -moz-transform: translateY(20px) translateX(-25%);
  -ms-transform: translateY(20px) translateX(-25%);
  -o-transform: translateY(20px) translateX(-25%);
}

.blurredText {
  margin-top: 1rem;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  position: relative;
}

.blurredText::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sameBtn {
  width: 11.5625rem;
  height: 3.75rem;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.125rem;
}

.or {
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #aeb3be;
}

@media (max-width: 1080px) {
  .left,
  .headerText .faq,
  .modal {
    display: none;
  }

  .content {
    padding-top: 60px;
  }

  .comparision-item {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .twoGrid {
    grid-template-columns: 1fr;
  }

  .mob-comparision-item {
    margin-right: 35px;
    margin-left: 10px;
  }

  .or {
    margin-bottom: 0.5rem;
  }

  .legalSection {
    padding-right: 1.5rem;
  }

  .header {
    height: 68px;
    min-height: 68px;
    padding-left: 24px;
    padding-right: 37px;
  }

  .mob-only {
    display: inherit;
  }

  .pc-only {
    display: none;
  }

  .App {
    height: auto;
  }

  .language {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .logo svg {
    width: 48px;
  }

  .content {
    padding-top: 48.5px;
  }

  .comparision-item {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  .right {
    padding-left: 24px;
  }

  .headerText {
    font-size: 28px;
    line-height: 35px;
  }

  .legalHeader {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
  }

  .smText {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    max-width: 80%;
  }

  #question {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    height: 45vh;
    padding-right: 1rem;
  }

  .right {
    margin-bottom: 117px;
  }

  .mob {
    position: fixed;
    padding-top: 24px;
    padding-bottom: 21px;
    padding-left: 8px;
    padding-right: 8px;
    bottom: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    background: rgba(209, 209, 214, 0.4);
    backdrop-filter: blur(20px);
  }

  .mob .btn {
    max-width: none;
    width: 100%;
    margin-bottom: 9px;
    font-weight: 900;
    font-size: 14px;
    min-height: 48px;
    border-radius: 10px;
    line-height: 18px;
  }

  .calculate {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    max-width: none;
    color: #5f5f5f;
    text-align: center;
  }

  .mob .btn svg {
    display: none;
  }

  .flinnCompare {
    max-width: none;
    margin-top: 10px;
    background: #fafafa;
  }

  .fees {
    max-width: none;
    padding-right: 25px;
  }

  .legalSection .smText {
    max-width: 43ch;
  }

  .fees .smText {
    max-width: 30ch;
  }

  .loading-content {
    margin-top: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .loadingText {
    text-align: start;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    max-width: 25ch;
  }

  .barContainer {
    max-width: 297px;
  }

  .mob.btns {
    height: auto;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    gap: 4px;
  }

  .downloadBtn {
    padding: 12px 1.125rem;
  }

  .mob .downloadBtn svg {
    display: block;
    margin-left: 0;
  }

  .analysisText {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }

  .analysisContainer {
    margin-bottom: 0;
    width: 100%;
  }

  .languageDropdown {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .flinnCompare {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }

  .lang {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
  }

  .pb-2 {
    padding-bottom: 0rem;
  }
}
